import React, { useEffect, useState } from "react"
import Button from "react-bootstrap/Button"
import useRazorpay from "react-razorpay"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import { useForm } from "react-hook-form"
import { navigate } from "gatsby"
import Countries from "../pages/countries.json"
import Table from "react-bootstrap/Table"

import {} from "./countries"
export default function Register() {
  const Razorpay = useRazorpay()
  const [filterDiscountData, setFilterDiscount] = useState(0)
  const [registrationData, setRegistrationData] = useState([])

  // ==================Vishakhapatnam Set State===================
  const [vishakhaDelegateData, setVishakhaDelegateData] = useState({})
  const [isVishakhapatnam, setVishakhapatnam] = useState(true)
  const [priceVishakha, setVishakhaPrice] = useState(0)

  // ==================Vishakhapatnam Duration===================
  const handleChangeVishakhapatnamDuration = event => {
    const { name, value } = event.target
    vishakhaDelegateData[name] = value
    setVishakhaDelegateData({ ...vishakhaDelegateData })

    if (vishakhaDelegateData["city_duration_pair[0].duration"] === "day") {
      const priceCity = 5000
      setVishakhaPrice(priceCity)
    } else if (
      vishakhaDelegateData["city_duration_pair[0].duration"] === "residential"
    ) {
      const priceCity = 20000
      setVishakhaPrice(priceCity)
    } else if (vishakhaDelegateData["city_duration_pair[0].duration"] === "") {
      const priceCity = 0
      setVishakhaPrice(priceCity)
    }
  }
  const handleChangeVishakhapatnam = event => {
    setVishakhapatnam(current => !current)
  }

  // ==================Delhi Set State===================
  const [delhiDelegateData, setDelhiDelegateData] = useState({})
  const [isDelhi, setDelhi] = useState(true)
  const [priceDelhi, setDelhiPrice] = useState(0)

  // ==================Delhi Duration===================
  const handleChangeDelhiDuration = event => {
    const { name, value } = event.target
    delhiDelegateData[name] = value
    setDelhiDelegateData({ ...delhiDelegateData })

    if (delhiDelegateData["city_duration_pair[3].duration"] === "day") {
      const priceCity = 7500
      setDelhiPrice(priceCity)
    } else if (
      delhiDelegateData["city_duration_pair[3].duration"] === "residential"
    ) {
      const priceCity = 25000
      setDelhiPrice(priceCity)
    } else if (delhiDelegateData["city_duration_pair[3].duration"] === "") {
      const priceCity = 0
      setDelhiPrice(priceCity)
    }
  }
  const handleChangeDelhi = event => {
    setDelhi(current => !current)
  }
  useEffect(() => handleChangeDelhi(), [])

  var priceAddition = priceVishakha + priceDelhi
  // EARLY BIRD
  const early_bird =
    new Date().getTime() < new Date("2023-03-10T00:00:00+05:30").getTime()

  const registration_end =
    new Date().getTime() > new Date("2023-03-14T00:00:00+05:30").getTime()

  if (early_bird === true)
    priceAddition = priceAddition - (priceAddition * 10) / 100

  if (filterDiscountData !== 0) {
    priceAddition = priceAddition - (priceAddition * filterDiscountData) / 100
  }

  // if more than 1 city selected
  if (priceVishakha && priceDelhi) {
    priceAddition = priceAddition - (priceAddition * 10) / 100
  }

  // COUPON CODE LOGIC
  const [couponCode, setCouponCode] = useState({})
  const [couponCodeText, setErrorCouponCode] = useState("")

  const handleCoponCode = async event => {
    const { name, value } = event.target
    couponCode[name] = value
    setCouponCode({ ...couponCode })

    const getData = await fetch(`${process.env.URL}/api/coupons`)
    let json = await getData.json()
    json = json["data"]
    if (couponCode.coupon_code.length > 0) {
      setErrorCouponCode("Not a valid coupon")
    } else {
      setErrorCouponCode("")
    }

    // Filter through the event type and coupon code.
    const data_filter = json.filter(jsonFilter => {
      if (
        jsonFilter.attributes.event_type === "event-registration" &&
        jsonFilter.attributes.coupon_code === couponCode.coupon_code &&
        jsonFilter.attributes.is_active === true
      ) {
        return jsonFilter
      } else if (
        jsonFilter.attributes.event_type === "event-registration" &&
        jsonFilter.attributes.coupon_code !== couponCode.coupon_code
      )
        return setFilterDiscount(0)
    })

    if (data_filter.length > 0) {
      const discount = data_filter[0].attributes.discount
      setFilterDiscount(discount)
      if (data_filter[0].attributes.coupon_code === couponCode.coupon_code) {
        setErrorCouponCode("")
      }
    }
  }

  var totalPrice = priceAddition * 1.18

  const completeRazorpayOrder = order =>
    new Promise(res => {
      const { amount, razorpay_order_id, id } = order
      var options = {
        // LIve keys
        // key: "rzp_live_sghxYlxlQshHZj", // Enter the Key ID generated from the Dashboard
        //  Testing keys
        key: "rzp_test_xL98of2uJRdFiI", // Enter the Key ID generated from the Dashboard
        amount: amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Unifiers Social Ventures Pvt. Ltd.",
        description: "Payment completion",
        image: "https://example.com/your_logo",
        order_id: razorpay_order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: function (response) {
          navigate("/registrationComplete")

          // alert(`
          //   Success
          //   ${JSON.stringify({
          //     payment_id: response.razorpay_payment_id,
          //     order_id: response.razorpay_order_id,
          //     signature: response.razorpay_signature,
          //   })}
          // `)
        },
        notes: {
          orderId: id,
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      }

      var rzp1 = new window.Razorpay(options)
      rzp1.on("payment.failed", function (response) {
        navigate("/error")
      })

      rzp1.open()
    })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      city_duration_pair: [{}, {}, {}, { city: "Delhi" }],
    },
  })

  const handleRegistrationData = async data => {
    const getData = await fetch(
      `${process.env.URL}/api/event-registrations?populate=*`
    )
    let json = await getData.json()
    json = json["data"]
    setRegistrationData(json)
  }

  const onSubmit = async formData => {
    const city_duration_pair = formData.city_duration_pair.filter(
      city_duration_pair_filtered => {
        if (
          city_duration_pair_filtered.city !== false &&
          city_duration_pair_filtered.duration !== ""
        ) {
          return city_duration_pair_filtered
        }
      }
    )

    if (priceDelhi <= 0) return alert("Please Select a City and Duration")

    const payload = {
      data: {
        entity: {
          type: "event-registration",
          eventId: 1,
        },
        operation: "insert",
        coupon_code: formData.coupon_code,
        data: {
          full_name: formData.full_name,
          designation: formData.designation,
          company_name: formData.company_name,
          email: formData.email,
          phone_number: formData.phone_number,
          country: formData.country,
          city_duration_pair: city_duration_pair,
        },
      },
    }

    console.log(payload)

    try {
      if (totalPrice === 0) {
        const result = await axios.post(
          `${process.env.URL}/api/orders`,
          payload
        )

        if (result.status === 200) {
          return navigate("/registrationComplete")
        } else {
          toast.error("Something gone wrong!", {
            position: toast.POSITION.TOP_CENTER,
          })
        }
      }

      const paymentURL = new URL(
        "http://esg-payment.skillstrainer.in/?payloadBase64="
      )

      const payLOadJson = JSON.stringify(payload)
      const payloadBase64 = window.btoa(payLOadJson)

      paymentURL.searchParams.set("payloadBase64", payloadBase64)
      window.open(paymentURL.href, "_self")
    } catch (err) {
      console.log("I am error", err)
    }

    // const { order: createdOrder } = result
    // await completeRazorpayOrder(createdOrder)
  }

  return (
    <>
      <div className="container mt-5">
        <div className="card shadow-lg">
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <ToastContainer />
              <div className="row">
                <div className="col-md-6">
                  <label className="mt-4">Full Name</label>
                  <input
                    placeholder="Full Name"
                    className="form-control"
                    type="text"
                    {...register("full_name", {
                      required: true,
                      minLength: 3,
                    })}
                  />
                  {errors.full_name && (
                    <p className="text-danger">Please check the First Name</p>
                  )}
                </div>
                <div className="col-md-6">
                  <label className="mt-4">Designation</label>
                  <input
                    placeholder="Enter Designation"
                    className="form-control"
                    type="text"
                    {...register("designation", {
                      required: true,
                      minLength: 2,
                    })}
                  />
                  {errors.designation && (
                    <p className="text-danger">Please check the Designation</p>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <label className="mt-4">Company Name</label>
                  <input
                    className="form-control"
                    placeholder="Enter Company Name"
                    type="text"
                    {...register("company_name", {
                      required: true,
                      minLength: 3,
                    })}
                  />
                  {errors.company_name && (
                    <p className="text-danger">Please check the Company Name</p>
                  )}
                </div>
                <div className="col-md-6">
                  <label className="mt-4">Email</label>
                  <input
                    className="form-control"
                    placeholder="Email"
                    type="email"
                    {...register("email", {
                      required: true,
                      pattern:
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    })}
                  />
                  {errors.email && (
                    <p className="text-danger">Please check the Email</p>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <label className="mt-4">Phone Number</label>
                  <input
                    className="form-control"
                    placeholder="Enter Phone Number"
                    type="number"
                    {...register("phone_number", {
                      required: true,
                      pattern: /^\d{10}$/,
                    })}
                  />
                  {errors.phone_number && (
                    <p className="text-danger">Please check the Phone Number</p>
                  )}
                </div>
                <div className="col-md-6">
                  <label className="mt-4">Country</label>
                  <select
                    className="form-control"
                    {...register("country", {
                      required: true,
                      minLength: 3,
                    })}
                  >
                    {Countries.map(country => (
                      <option value={country.name}>{country.name}</option>
                    ))}
                  </select>
                  {errors.country && (
                    <p className="text-danger">Please check the Country</p>
                  )}
                </div>
              </div>
              <h4 className="mt-4 text-center">Select Your Plan</h4>
              <div className="row">
                <div className="col-md-2">
                  <input
                    type="checkbox"
                    className="hidden"
                    value="Delhi"
                    {...register("city_duration_pair[3].city", {
                      onChange: handleChangeDelhi,
                    })}
                    defaultChecked={true}
                  />
                  <label>New Delhi</label>
                </div>
                <div className="col-md-6">
                  {isDelhi !== true && (
                    <form>
                      <label className="">Duration</label>
                      <br />
                      <select
                        {...register("city_duration_pair[3].duration", {
                          onChange: handleChangeDelhiDuration,
                        })}
                        className="form-control"
                      >
                        <option value="">Choose an option</option>
                        <option value="day">Day Delegate</option>
                        <option value="residential">
                          Residential Delegate
                        </option>
                      </select>
                      {errors.delegateDuration && (
                        <p className="text-danger">Please Select One</p>
                      )}
                    </form>
                  )}
                </div>
                <div className="col-md-4">
                  {isDelhi !== true && priceDelhi ? (
                    <div className="pt-3">
                      Price: Rs. {priceDelhi}/- + 18% GST
                    </div>
                  ) : (
                    <p></p>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <label className="mt-4">Do you have a Coupon Code ?</label>
                  <input
                    className="form-control"
                    placeholder="Enter Coupon Code"
                    type="text"
                    {...register("coupon_code", {
                      onChange: handleCoponCode,
                    })}
                  />
                  <p className="text-danger">{couponCodeText}</p>
                </div>

                <div className="col-md-4"></div>
                <div className="col-md-2">
                  <div className="pt-3">Total:</div>
                </div>
                <div className="col-md-2">
                  <div className="pt-3">Rs. {Math.round(totalPrice)} /-</div>
                </div>
                <label className="mt-4 fw-bold text-primary">
                  If more than one city is selected, you will get 10% discount
                  on the total amount.
                </label>

                <label className="mt-4 fw-bold text-primary">
                  Early bird registration gets 10% discount. Register before
                  March 10th, 2023 to avail the discount!
                </label>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <Button
                    className="button mt-4"
                    type="submit"
                    disabled={registration_end}
                  >
                    Submit
                  </Button>
                </div>
                <div className="col-md-12"></div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* <Table responsive striped bordered className="table-custom">
        <thead>
          <tr>
            <th>Name</th>
            <th>Designation</th>
            <th>Company Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>City</th>
            <th>Duration</th>
          </tr>
        </thead>
        <tbody>
          {registrationData &&
            registrationData.map(data => {
              // let city_duration_pair = data.attributes.city_duration_pair
              // city_duration_pair = city_duration_pair.map(data => {
              //   return data.city, data.duration
              // })
              // console.log("city_duration_pair===", city_duration_pair)
              return (
                <>
                  <tr>
                    <td className="col-md-1">{data.attributes.full_name}</td>
                    <td className="col-md-1">{data.attributes.designation}</td>
                    <td className="col-md-1">{data.attributes.company_name}</td>
                    <td className="col-md-2">{data.attributes.email}</td>
                    <td className="col-md-1">{data.attributes.phone_number}</td>
                    <td className="col-md-1">
                      {data.attributes.city_duration_pair.map((data, index) => {
                        console.log(index, data)
                        return (index ? ", " : "") + data.city
                      })}
                    </td>
                    <td className="col-md-1">
                      {data.attributes.city_duration_pair.map((data, index) => {
                        console.log(index, data)
                        return (index ? ", " : "") + data.duration
                      })}
                    </td>
                  </tr>
                </>
              )
            })}
        </tbody>
      </Table>
      <Button
        className="button mt-4"
        type="button"
        onClick={handleRegistrationData}
      >
        Get Registration Data
      </Button> */}
    </>
  )
}
